import styled from "@emotion/styled";

interface GridStaticProps {
    width?: string;
}

export const GridStatic = styled.div<GridStaticProps>`
    width: ${(props) => props.width || "1030px"};
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
`;
