import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const MarkerIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "3.61mm"} height={props.height || "4.42mm"} viewBox="0 0 10.23 12.54">
            <path
                d="M10.17,4.26A4.87,4.87,0,0,0,9.05,1.74,5.81,5.81,0,0,0,5.12,0h0A5.81,5.81,0,0,0,1.18,1.74,4.87,4.87,0,0,0,.06,4.26,6.09,6.09,0,0,0,.25,6.87,6.76,6.76,0,0,0,1.32,9.08a15.89,15.89,0,0,0,2.89,3.15,1.61,1.61,0,0,0,.91.31,1.6,1.6,0,0,0,.9-.31A15.89,15.89,0,0,0,8.91,9.08,6.76,6.76,0,0,0,10,6.87,6.09,6.09,0,0,0,10.17,4.26ZM5,7.54A2.38,2.38,0,1,1,7.39,5.16,2.39,2.39,0,0,1,5,7.54Z"
                fill={props.mainColor || "#8b6aac"}
            />
        </svg>
    );
};
