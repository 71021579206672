import {useContext} from "react";

import {UserDeviceContext} from "../context/UserDeviceContext";

export const useUserDevice = () => {
    const context = useContext(UserDeviceContext);

    if (!context) {
        throw new Error("No UserDeviceContext found when calling useUserDevice.");
    }

    return context;
};
