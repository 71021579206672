import React, {useEffect, useRef, useState} from "react";
import {css, cx} from "@linaria/core";
import {useMounted} from "@web2/react_utils";

import {IOfferBoxPicture} from "../../../../../app/interfaces/response/offer_detail";
import {getThemeBreakpoint} from "../../../../../styles/linaria_variable_factory";

import offerPlaceholder306x171 from "../../../../../styles/assets/images/offer_placeholder_306x171.jpg";
import offerPlaceholder360x171 from "../../../../../styles/assets/images/offer_placeholder_360x171.jpg";

interface IProps {
    image: IOfferBoxPicture | null;
    idx: number;
    alt: string;
    preventLazyLoad?: boolean;
    allowImageFadeIn?: boolean | undefined;
    className?: string;
    currentSliderIndex?: number;
    slidesCount: number;
}

const INITIAL_NEAREST_VISIBLE_IMAGES_COUNT = 0;
const NEAREST_VISIBLE_IMAGES_COUNT = 2;

export const SingleGalleryImage = (props: IProps) => {
    const {image, idx, alt, preventLazyLoad, currentSliderIndex, slidesCount} = props;
    const pictureContainer = useRef<HTMLImageElement>(null);
    const nearestVisibleImagesCount = useRef<number>(INITIAL_NEAREST_VISIBLE_IMAGES_COUNT);

    const [isImageError, setIsImageError] = useState(false);
    const isMounted = useMounted();
    useEffect(() => {
        // fix: we did get some image urls that returned 403 from amazon.
        // `onError` callback should set the state to show placeholder, but it does not always trigger right after a page load.
        // Change on error is forced here.
        if (isMounted && pictureContainer.current?.complete && pictureContainer.current?.naturalWidth === 0) {
            setIsImageError(true); // hide image sources
        }
    }, [isMounted]);
    const replaceImageOnError = () => {
        // onError does not always trigger on page load, but is working correctly on live SPA behaviour
        setIsImageError(true); // hide image sources
    };

    // limit files that user needs to download: initially load only images in slides nearest to centered slide
    const isNearestImage = Number.isFinite(currentSliderIndex) ? Math.abs(idx - (currentSliderIndex as number)) <= nearestVisibleImagesCount.current : false;

    const wasImageLoaded = useRef(currentSliderIndex === idx);
    useEffect(() => {
        if (!wasImageLoaded.current && isNearestImage) {
            // remember if image was already loaded
            wasImageLoaded.current = true;
        }

        if (currentSliderIndex !== 0) {
            nearestVisibleImagesCount.current = NEAREST_VISIBLE_IMAGES_COUNT;
        }
    }, [currentSliderIndex]);

    // show pictures that are near the current slide or were loaded in the past
    // also include some images at the end of the list - those images are cloned for the infinite slider, so they use the same files as first slides
    const showPicture = isNearestImage || wasImageLoaded.current || idx > slidesCount - nearestVisibleImagesCount.current;

    return (
        <picture
            onError={replaceImageOnError}
            className={cx(offerBoxPictureElement, props.allowImageFadeIn && imageFadeInOfferBoxPictureElement, props.className)}
        >
            {showPicture && !isImageError && (
                <>
                    <source media="(min-width: 640px)" width={306} height={171} srcSet={(image && image.o_img_306x171) || offerPlaceholder360x171} />
                </>
            )}

            <img
                src={showPicture && !isImageError && image && image.o_img_360x171 ? image.o_img_360x171 : offerPlaceholder306x171}
                width={360}
                height={171}
                alt={alt}
                ref={pictureContainer}
                loading={idx === 0 && preventLazyLoad ? "eager" : "lazy"}
                fetchpriority={idx === 0 && preventLazyLoad ? "high" : undefined}
            />
        </picture>
    );
};

const offerBoxPictureElement = css`
    width: 100%;
    height: inherit;
    overflow: hidden;
    position: relative;
    display: inline-block;

    > img {
        position: relative;
        left: 50%;
        width: auto;
        max-width: 100%;
        transform: translateX(-50%);
        object-fit: cover;
    }
`;

const imageFadeInOfferBoxPictureElement = css`
    animation: fadeInImage 0.1s ease-in;

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        animation: fadeInImage 0.2s ease-in;
    }

    @keyframes fadeInImage {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.1;
        }
    }
`;
