import {favouriteAlgolytics} from "../init_algolytics";
import {getTrackedDesktopSiteData, getTrackedMobileSiteData} from "../tracked_site";

export interface IFavouriteSentData {
    isMobile: boolean | null;
    checked: boolean;
    offer: {
        id: string;
    };
}

export const favouriteClickAlgolyticsHit = (favouriteSentData: IFavouriteSentData) => {
    const favouriteData = {
        checked: favouriteSentData.checked,
        offer_id: favouriteSentData.offer.id
    };

    const data = {
        ...favouriteData,
        ...(favouriteSentData.isMobile ? getTrackedMobileSiteData() : getTrackedDesktopSiteData())
    };
    favouriteAlgolytics(data);
};
