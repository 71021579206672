import {showPrimaryMarketOfferListPrices} from "../../app/utils/read_environment_variables";
import {OfferMarketType} from "./constants_offer";

interface OfferPriceConfig {
    show_price?: boolean;
    show_price_m2?: boolean;
    market_type: OfferMarketType;
}
export const getShowOfferPrice = (props: {offer: OfferPriceConfig; priceType: "total" | "m2"}) => {
    switch (true) {
        case props.offer.market_type === OfferMarketType.AFTERMARKET:
            return true;
        case !showPrimaryMarketOfferListPrices:
            return false;
        case props.priceType === "total" && typeof props.offer.show_price !== "undefined":
            return props.offer.show_price;
        case props.priceType === "m2" && typeof props.offer.show_price_m2 !== "undefined":
            return props.offer.show_price_m2;
        default:
            return true;
    }
};
