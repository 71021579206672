export enum LocationTypes {
    CITY = "Miasto",
    TOWN = "Miejscowość",
    DISTRICT = "Dzielnica",
    ESTATE = "Osiedle",
    COUNTY = "Powiat",
    VOIVODESHIP = "Województwo"
}

export type LocationPath = LocationPathElement[];

type LocationPathElement = {
    name?: string;
    slug?: string;
    type: LocationTypes | string;
};

export const getLocationFromPath = (path: LocationPath | null | undefined, locationType: LocationTypes): LocationPathElement | null => {
    if (!path) {
        return null;
    }
    return path.find((loc) => loc.type === locationType) || null;
};
