import React, {MouseEventHandler, useEffect, useMemo, useRef, useState} from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {ArrowWithOrientationIcon} from "@web2/icons";
import {getIsMobileMatch, useCallbackOnIntersection, useMounted, useStateRef} from "@web2/react_utils";
import {useUserDevice} from "@web2/user-device";

import {IOfferBoxPicture, IOfferBoxPlanPicture} from "../../../../../app/interfaces/response/offer_detail";
import {getThemeBreakpoint, getThemeVariable} from "../../../../../styles/linaria_variable_factory";
import {gtmOfferBoxGalleryArrowClick} from "../../../../../tracking/google_tag_manager/gtm_offer_box_gallery_arrows_click";
import {GestureDirection, useOfferboxTouchEvents} from "../utils/use_offerbox_touch_events";
import {SingleGalleryImage} from "./SingleGalleryImage";
import {SingleGalleryImagePlaceholder} from "./SingleGalleryImagePlaceholder";

interface IProps {
    offerName: string;
    offerPictures: IOfferBoxPicture[] | null;
    propertyPlanPicture?: IOfferBoxPlanPicture | null;
    showFirstImage?: boolean;
    preventLazyLoad?: boolean;
    forceSliderInit?: boolean;
    allowImageFadeIn?: boolean;
    isMultilead?: boolean;
}

export const SLIDER_ANIMATION_DURATION = 300;
const SLIDER_TRANSITION = "ease-out";
const INITIAL_IMAGES_COUNT = 1;
const CLONED_IMAGES_COUNT = 2;
export const OFFER_BOX_GALLERY_HEIGHT = 171;

// we use a few dummy slides to fill space until a proper slide is initialized.
// This approach eliminates possible slider "jumps" when coming into view on iOS and initializing slider
// using Array.from() to multiply placeholder X times
const TemporarySlidePlaceholders = () => (
    <>
        {Array.from({length: CLONED_IMAGES_COUNT}).map((_, i) => (
            <SingleGalleryImagePlaceholder key={i} />
        ))}
    </>
);

// I am using a lot of refs here to avoid issues with stale state being used inside event listeners. Reference to equal state value will always be up to date.

export const OfferBoxGallery = (props: IProps) => {
    const imagesCount = useRef(0);
    const preventAnimationRef = useRef(false);

    const [_, setIsAnimationPending, isAnimationPendingRef] = useStateRef(false);
    const galleryHolderRef = useRef<HTMLDivElement | null>(null);
    // const shouldAttachPlaceholder = (!props.offerPictures || !props.offerPictures.length) && !props.propertyPlanPicture;
    const {isMobile} = useUserDevice();
    const isMounted = useMounted();

    const [isSliderLoaded, setIsSliderLoaded, isSliderLoadedRef] = useStateRef(false);

    /*
     * prepare images
     */

    const imagesList: IOfferBoxPicture[] = useMemo(() => {
        let imagesArr: IOfferBoxPicture[] = [];

        const imagesCollection = props.offerPictures || [];

        imagesArr = imagesCollection.filter((img) => img.o_img_306x171);

        // store count of images for slider positioning calculations
        imagesCount.current = imagesArr.length;

        // add cloned images when slider is initialized
        return isSliderLoaded
            ? [...imagesArr.slice(-1 * CLONED_IMAGES_COUNT), ...imagesArr, ...imagesArr.slice(0, CLONED_IMAGES_COUNT)]
            : imagesArr.slice(0, INITIAL_IMAGES_COUNT);
    }, [props.offerPictures, props.showFirstImage, props.propertyPlanPicture, isSliderLoaded]);

    /*
     * slider logic
     */

    const [translateValue, setTranslateValue] = useState(CLONED_IMAGES_COUNT * -100);
    const [currentIndex, setCurrentIndex, currentIndexRef] = useStateRef(0);

    // Defining timers
    let timerIsAnimationPending: NodeJS.Timeout;
    let timerGoToSlide: NodeJS.Timeout;
    let timerSkipToSlide: NodeJS.Timeout;

    useEffect(() => {
        if (props.forceSliderInit) {
            initializeSlider();
        }

        return () => {
            // Cleaning up timers on component unmount
            clearTimeout(timerIsAnimationPending);
            clearTimeout(timerGoToSlide);
            clearTimeout(timerSkipToSlide);
        };
    }, []);

    const initializeSlider = () => {
        // initialize on hover or when called ie. when touch gesture is detected
        if (!isSliderLoadedRef.current) {
            setIsSliderLoaded(true);
            skipToSlide(currentIndexRef.current);
        }
    };

    const initializeSliderOnIntersection = () => {
        // only initialize slider on intersection when in mobile
        if (getIsMobileMatch()) {
            initializeSlider();
        }
    };

    // initialize slider when visible on mobile
    useCallbackOnIntersection({ref: galleryHolderRef, callback: initializeSliderOnIntersection, deps: [isMounted, isMobile], triggerOnce: true});

    const onGestureEnd = (gestureDirection: GestureDirection) => {
        if (gestureDirection) {
            const nextSlide = currentIndexRef.current + gestureDirection;
            goToSlide(nextSlide);
        }
    };

    const {shouldBlockEventsRef} = useOfferboxTouchEvents(galleryHolderRef, initializeSlider, onGestureEnd, isMobile);

    /*
     * slide traversing methods
     */

    const goToSlide = (slide: number, force = false) => {
        if (isAnimationPendingRef.current && !force) {
            return;
        }
        // block new clicks until animation stops
        setIsAnimationPending(true);

        setCurrentIndex(slide);
        setTranslateValue((CLONED_IMAGES_COUNT + slide) * 100 * -1);

        timerIsAnimationPending = setTimeout(() => {
            setIsAnimationPending(false);
        }, SLIDER_ANIMATION_DURATION);

        timerGoToSlide = setTimeout(() => {
            if (slide < 0 || slide > imagesCount.current - 1) {
                // landed on a cloned slide, return to normal slide after animation stops
                skipToSlide(slide < 0 ? imagesCount.current - 1 : 0);
            }
        }, SLIDER_ANIMATION_DURATION);
    };

    const skipToSlide = (slide: number) => {
        // skip animation and show selected slide
        preventAnimationRef.current = true;
        goToSlide(slide, true);

        timerSkipToSlide = setTimeout(() => {
            preventAnimationRef.current = false;
        }, SLIDER_ANIMATION_DURATION); // Providing duration instead of 0 eliminates odd scrolling artifacts when hitting end of slides (mobile + Safari + Firefox) -  CU-862jdjnjh
    };

    /*
     * slide traversing - arrow click handlers
     */

    const handleSlideRight = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        gtmOfferBoxGalleryArrowClick();

        if (shouldBlockEventsRef.current) {
            return;
        }
        goToSlide(currentIndex + 1);
    };

    const handleSlideLeft = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        gtmOfferBoxGalleryArrowClick();
        if (shouldBlockEventsRef.current) {
            return;
        }
        goToSlide(currentIndex - 1);
    };

    /*
     * misc
     */

    const preventClickEventsOnGesture: MouseEventHandler = (e) => {
        // prevent click events when gesture is detected
        if (shouldBlockEventsRef.current) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    /*
     * render
     */

    if (props.showFirstImage && imagesList) {
        return (
            <div className={offerBoxGalleryHolder}>
                <Slider style={{transform: "translateX(0)"}} className={cx(!preventAnimationRef.current && animationSlider)}>
                    <SingleGalleryImage
                        image={props.offerPictures && props.offerPictures[0]}
                        idx={0}
                        alt={props.offerName}
                        slidesCount={1}
                        allowImageFadeIn={props.allowImageFadeIn}
                    />
                </Slider>
            </div>
        );
    }

    return (
        <div
            className={cx(offerBoxGalleryHolder, props.isMultilead && multileadBox)}
            ref={galleryHolderRef}
            onClick={preventClickEventsOnGesture}
            onMouseEnter={initializeSlider}
        >
            <Slider style={{transform: `translateX(${translateValue}%)`}} className={cx(!preventAnimationRef.current && animationSlider)}>
                {!isSliderLoaded && <TemporarySlidePlaceholders />}

                {imagesList.map((image: IOfferBoxPicture, idx: number) => (
                    <SingleGalleryImage
                        key={isSliderLoaded ? idx - CLONED_IMAGES_COUNT : idx} // keep same react key for the initial image regardless of slider state
                        image={image}
                        idx={isSliderLoaded ? idx - CLONED_IMAGES_COUNT : idx}
                        currentSliderIndex={currentIndex}
                        slidesCount={imagesCount.current}
                        className={currentIndex === idx - CLONED_IMAGES_COUNT ? "activeSlide" : ""} // class only for debugging purposes
                        alt={props.offerName}
                        preventLazyLoad={props.preventLazyLoad}
                        allowImageFadeIn={props.allowImageFadeIn}
                    />
                ))}
            </Slider>

            {imagesCount.current > 1 && (
                <div className={cx(arrow, "left")} onClick={handleSlideLeft}>
                    <ArrowWithOrientationIcon width={20} height={20} orientation="left" fill="#fff" />
                </div>
            )}

            {imagesCount.current > 1 && (
                <div className={cx(arrow, "right")} onClick={handleSlideRight}>
                    <ArrowWithOrientationIcon width={20} height={20} orientation="right" fill="#fff" />
                </div>
            )}
        </div>
    );
};

const offerBoxGalleryHolder = css`
    position: relative;
    margin: 0 -0.1rem;
    touch-action: pan-y pinch-zoom;
    background-color: ${getThemeVariable("colors-gray_very_dark")};
`;
const multileadBox = css`
    @media (min-width: ${getThemeBreakpoint().screen_sm}) {
        overflow: hidden;
        max-width: 25.8rem;
    }
`;

const Slider = styled.div`
    width: 100%;
    height: ${OFFER_BOX_GALLERY_HEIGHT}px;
    white-space: nowrap;
`;

const animationSlider = css`
    transition: transform ${SLIDER_ANIMATION_DURATION}ms ${SLIDER_TRANSITION} 0s;
`;

const gradientColor = "rgba(0, 0, 0, 0) 64%, rgba(0,0,0,0.2) 100%";

const arrow = css`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 100%;
    transition: opacity 150ms ease-out;
    outline: none;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .svg-icon {
        color: white;
        font-size: 3rem;
        outline: none;
        user-select: none;
    };

    &.left {
        left: 0;
        background-image: linear-gradient(-90deg, ${gradientColor});

        .svg-icon {
            left: 1.6rem
        }
    };

    &.right {
        right: 0;
        background-image: linear-gradient(90deg, ${gradientColor});

        .svg-icon {
            right: 1.6rem
        }
    };
}`;
