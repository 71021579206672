import {cloneDeep} from "lodash";
import {getCookie} from "@web2/request_utils";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {gtmId} from "../../app/utils/read_environment_variables";
import {ViewType} from "../view_type/view_type";
import {IViewTypeMetaData} from "../view_type/view_type_actions";
import {gtmPageView} from "./gtm_page_view";

export const ghGtmInit = (state: IStore): void => {
    configGoogleTagManager(state.viewType.current, state.viewType.currentData);
    initGoogleTagManager();
};

declare global {
    interface Window {
        dataLayer: any[];
        pgGtmLib: {
            gtmHitLib: (gtmData: IGtmData) => void;
        };
    }
}

const GTM_ID = gtmId;
let isGtmInitialized = false;

/**
 * Init
 */
export const initGoogleTagManager = (): void => {
    const win = window as Window;

    if (!gtmId) {
        return;
    }

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // initialize only once
    if (isGtmInitialized) {
        return;
    }
    // fetch/init GTM
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (function (w: Window, d, s: "script", dataLayer: "dataLayer", gtmId: string) {
        w[dataLayer] = w[dataLayer] || [];
        w[dataLayer].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js"
        });
        const firstScriptElem = d.getElementsByTagName(s)[0] as HTMLScriptElement;
        const newScriptElem = d.createElement(s);
        newScriptElem.async = true;
        newScriptElem.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        firstScriptElem.parentNode?.insertBefore(newScriptElem, firstScriptElem);
    })(win, document, "script", "dataLayer", GTM_ID);

    isGtmInitialized = true;
};

/**
 * Config
 */

export const configGoogleTagManager = (viewType: ViewType | null, currentData: Partial<IViewTypeMetaData>): void => {
    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // on page load run script to group future content sent do GTM
    gtmPageView(viewType, currentData);
};

/**
 * Hit
 */
const MAX_IMPRESSIONS_PER_HIT = 6;

export interface IGtmData {
    GAEventCategory?: string;
    GAEventLabel?: string;
    GAEventAction?: string;
    GAEventNoInteraction?: boolean;
    event?: string;
    ecommerce?: {
        checkout?: unknown;
        currencyCode?: unknown;
        impressions?: object[];
        purchase?: unknown;
        promoClick?: unknown;
        promoView?: unknown;
    };
}

let savedCookie: string | undefined = "";
export const hitGoogleTagManager = (data: IGtmData) => {
    const win = window as Window;

    if (!savedCookie) {
        savedCookie = getCookie("analyticssessionid");
    }

    const gtmData = {
        ...data,
        ...(savedCookie ? {session_id: savedCookie} : {})
    };

    // console.log("hitGoogleTagManager: ", (gtmData as any).event, gtmData); // eslint-disable-line @typescript-eslint/no-explicit-any

    win.dataLayer = win.dataLayer || [];
    if ((gtmData.ecommerce?.impressions?.length ?? 0) > MAX_IMPRESSIONS_PER_HIT) {
        // start impressions chunking
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const impressions = gtmData!.ecommerce!.impressions!; // because we validate that field earlier
        const chunks = Math.ceil(impressions.length / MAX_IMPRESSIONS_PER_HIT);
        for (let i = 0; i < chunks; ++i) {
            const chunkData = cloneDeep(gtmData);
            if (chunkData.ecommerce) {
                chunkData.ecommerce.impressions = impressions.slice(i * MAX_IMPRESSIONS_PER_HIT, (i + 1) * MAX_IMPRESSIONS_PER_HIT);
            }
            win.dataLayer.push(chunkData);
        }
    } else {
        win.dataLayer.push(gtmData);
    }
};
