import {capitalizeFirst} from "@web2/nodash";

import {floorFormat, sizeFormat} from "../../app/utils/number";
import {IStringIndex} from "../../search/components/filters/obsolete_range_filter/models";
import {dealTypePL, getPropertyTypeName, OfferDealType, OfferMarketType, PropertyType} from "./constants_offer";
import {parseOfferLocation, pluralizedRoomsNumber} from "./utils";

const propertyTypes: IStringIndex<IStringIndex> = {
    aftermarket: {
        apartment: "Mieszkanie",
        house: "Dom",
        investment: "Budynek",
        lot: "Działka"
    },
    primary_market: {
        apartment: "Nowe mieszkanie",
        house: "Nowy dom",
        investment: "Inwestycja",
        lot: "Działka"
    }
};

type IPath = {
    name: string;
    slug: string;
    type: string;
};

export interface IOfferHeading {
    slug: string;
    investment?: {
        name: string;
    } | null;
    market_type: OfferMarketType;
    deal_type: OfferDealType;
    offer_type?: string[];
    property: {
        address_details?: {
            city: string;
            street: string;
            district: string;
            housing_estate: string;
        } | null; //
        size: number | string | null;
        address?: string | null;
        type: string;
        floors?: number;
        floor?: number | null;
        room_number?: number | null;
        location?: {
            path?: IPath[];
            short_name: string;
        } | null;
    };
}

export const getOfferHeading = (offerElem: IOfferHeading) => {
    const {property, deal_type, investment} = offerElem;
    const investmentName = `${(investment && investment.name) || ""}`;
    const dealType = deal_type === OfferDealType.SELL ? dealTypePL(OfferDealType.SELL) : dealTypePL(OfferDealType.RENT);

    let heading = "";

    if (property.type === PropertyType.APARTMENT) {
        const singleApartment = property.room_number === 1;
        const apartmentType = singleApartment ? `Kawalerka` : propertyTypes[offerElem.market_type][offerElem.property.type];
        const roomString = !singleApartment ? `${offerElem.property.room_number} pokojowe` : "";
        const size = offerElem.property.size ? `${offerElem.property.size} m2` : "";
        const floors = offerElem.property.floors ? (offerElem.property.floors === 0 ? "parter" : `${offerElem.property.floors} piętro`) : "";

        heading = `${apartmentType} ${roomString} ${size} ${floors} ${dealType} ${investmentName}`;
    }
    if (property.type === PropertyType.HOUSE) {
        const roomString = offerElem.property.room_number ? `${offerElem.property.room_number} pokojowy` : "";
        heading = `${propertyTypes[offerElem.market_type][offerElem.property.type]} ${roomString} ${dealType} ${investmentName}`;
    }
    if (property.type === PropertyType.LOT) {
        heading = `${propertyTypes[offerElem.market_type][offerElem.property.type]} ${dealType}`;
    }
    return {heading};
};

export const getMultileadOfferHeading = (offerElem: IOfferHeading) => {
    const {property, investment, offer_type} = offerElem;
    const investmentName = `${(investment && investment.name) || ""}`;
    const isPrimaryMarket = offer_type && offer_type.includes(OfferMarketType.PRIMARY_MARKET);

    const parsedLocation = parseOfferLocation(offerElem);
    const street = property.address_details?.street;

    const regexForStreetPrefix = /ul. |al. |rondo |aleja |os. |pl. |plac /i;
    const testForStreetPrefix = (street && regexForStreetPrefix.test(street)) || false;

    const streetName = !isPrimaryMarket && testForStreetPrefix ? street : (!isPrimaryMarket && street && `ul. ${street}`) || "";

    const propertySize = property.size ? `${sizeFormat(property.size)}m2` : "";

    const propertyFloors =
        property?.floors !== null && property.floors !== undefined && Number.isFinite(property.floors) ? `${floorFormat(property.floors)},` : "";
    const propertyFloor = property.floor !== null && property.floor !== undefined && Number.isFinite(property.floor) ? `${floorFormat(property.floor)}` : "";
    const floor = investment ? propertyFloors : propertyFloor;
    let heading = "";

    if (property.type === PropertyType.APARTMENT) {
        const singleApartment = property.room_number === 1;
        const apartmentType = singleApartment ? `Kawalerka` : capitalizeFirst(getPropertyTypeName(PropertyType.APARTMENT));
        const roomString = !singleApartment ? pluralizedRoomsNumber(offerElem.property.room_number) : "";

        heading = `${apartmentType}, ${roomString}, ${propertySize}, ${floor} ${investmentName}${streetName}`;
    }
    if (property.type === PropertyType.HOUSE) {
        const roomString = offerElem.property.room_number ? `${property.room_number} pokojowy ` : "";
        heading = `${capitalizeFirst(getPropertyTypeName(PropertyType.HOUSE))}, ${roomString}, ${propertySize}, ${investmentName}${streetName}`;
    }
    if (property.type === PropertyType.LOT) {
        heading = `${propertyTypes[offerElem.market_type][offerElem.property.type]} ${streetName}`;
    }
    return {heading, parsedLocation};
};
