import {MouseEvent} from "react";
import {styled} from "@linaria/react";
import {appLink, parseInvestmentSlugToObject} from "@web2/gh_routes";
import {pluralize} from "@web2/string_utils";

import {getThemeVariable} from "../../../../../styles/linaria_variable_factory";
import {ellipsis} from "../../../../../styles/mixins_linaria";

interface IOfferBadge {
    isPrivateOffer: boolean;
    investment: {
        name: string;
        offer_count?: number;
        slug: string;
    } | null;
}

export const OfferBadge = (props: IOfferBadge) => {
    const isPrivateOffer = props.isPrivateOffer;
    const offerPlural = pluralize(["oferta", "oferty", "ofert"]);
    const investmentTitle =
        props.investment && props.investment.offer_count
            ? `${props.investment.name} - ${props.investment.offer_count} ${offerPlural(props.investment.offer_count)}`
            : "";
    const badgeTitle = isPrivateOffer ? "Oferta prywatna" : investmentTitle;

    const investmentUrl = props.investment && appLink.investment.detail(parseInvestmentSlugToObject(props.investment.slug));

    const handleInvestmentUrl = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (investmentUrl) {
            window.location.href = investmentUrl;
        }
    };

    return isPrivateOffer ? (
        <StyledOfferBadge isPrivateOffer={isPrivateOffer}>
            <div>{badgeTitle}</div>
        </StyledOfferBadge>
    ) : (
        <StyledOfferBadge as="a" href={investmentUrl} onClick={handleInvestmentUrl}>
            <div>{badgeTitle}</div>
        </StyledOfferBadge>
    );
};

export const StyledOfferBadge = styled.div<{isPrivateOffer?: boolean; href?: string | null}>`
    position: absolute;
    top: 151px;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    transition:
        background-color 100ms ease-out,
        color 100ms ease-out;
    background-color: ${(props) => (props.isPrivateOffer ? "#009688" : "#5a9de6")};
    width: 100%;
    height: 2rem;

    &:is(:hover, :focus, :active, :focus:active) {
        text-decoration: none;
    }

    > div {
        ${ellipsis};
        height: 100%;
        width: 100%;
        padding: 0.2rem 0 0 2.8rem;
        color: ${getThemeVariable("colors-text_white")};
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 6%, rgba(0, 0, 0, 0) 94%, rgba(0, 0, 0, 0.2) 100%);
    }
`;
