import React from "react";
import {css, cx} from "@linaria/core";

import {getThemeVariable} from "../../../../../styles/linaria_variable_factory";
import {SkeletonBox} from "./SkeletonBox";

interface IProps {
    className?: string;
}

export const SingleGalleryImagePlaceholder = (props: IProps) => {
    return <SkeletonBox className={cx(offerBoxPictureElement, props.className)} />;
};

const offerBoxPictureElement = css`
    overflow: hidden;
    position: relative;
    display: inline-block;
    background-color: ${getThemeVariable("colors-gray_very_bright")};

    > img {
        position: relative;
        left: 50%;
        width: auto;
        max-width: 100%;
        transform: translateX(-50%);
    }
`;
