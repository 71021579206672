import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const UserIcon = (props: IProps) => {
    const {size = "1"} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size} className={className} fillColor={props.fillColor}>
            <path
                d="M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5Z"
                fill={props.secondaryFillColor}
                stroke={props.fillColor}
                strokeWidth={1 / parseInt(size)} // we need 1px here
            />
            <path d="M6.0119 3.3393C6.0119 3.89486 5.56153 4.34525 5.00595 4.34525C4.45038 4.34525 4 3.89486 4 3.3393C4 2.78373 4.45038 2.33334 5.00595 2.33334C5.56153 2.33334 6.0119 2.78373 6.0119 3.3393Z" />
            <path d="M5.00593 7.66668C3.69544 7.66668 2.83331 7.36119 2.83331 6.95293C2.83331 6.07536 3.49194 4.84225 4.99998 4.84225C6.50801 4.84225 7.16665 6.25447 7.16665 6.95293C7.16665 7.25382 6.31643 7.66668 5.00593 7.66668Z" />
        </SvgIcon>
    );
};
