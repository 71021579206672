import {MouseEvent} from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {CloseIcon} from "@web2/icons";

import {getThemeVariable} from "../../../../styles/linaria_variable_factory";
import {PropertyType} from "../../../utils/constants_offer";
import {OFFER_BOX_GALLERY_HEIGHT} from "./components/OfferBoxGallery";
import {OFFERBOX_STANDARD_HEIGHT} from "./OfferBox";

interface Props {
    isOpen: boolean;
    onClose: (e: MouseEvent) => void;
    offer: {
        description?: string;
        property: {
            type: PropertyType;
        };
    };
}

export const OfferBoxGeneratedDescription = (props: Props) => {
    const {offer} = props;

    if (!offer.description) {
        return null;
    }

    return (
        <DescriptionHolder isOpen={props.isOpen} propertyType={props.offer.property.type}>
            <div className={descriptionTextStyle}>{offer.description}</div>
            <span className={closeBtnStyles} onClick={props.onClose}>
                <CloseIcon size={"1.2"} />
            </span>
        </DescriptionHolder>
    );
};

const descriptionTextStyle = css`
    padding: 1rem 0 1rem 1rem;
    line-height: 1.4rem;
    font-size: 1rem;
`;

const DescriptionHolder = styled.div<{isOpen: boolean; propertyType: PropertyType}>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 1;
    transition: height 0.3s ease-out;
    overflow: hidden;
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: 300;
    background-color: #fff;
    color: ${getThemeVariable("colors-gray_dark")};
    height: ${(props) => (props.isOpen ? `${OFFERBOX_STANDARD_HEIGHT - OFFER_BOX_GALLERY_HEIGHT - 2}px` : 0)};
`;

const closeBtnStyles = css`
    padding: 0.2rem 2.1rem;
    font-size: 2rem;
`;
