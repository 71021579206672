import {css, cx} from "@linaria/core";

import {getThemeVariable} from "../../../../../styles/linaria_variable_factory";

interface IProps {
    className?: string;
    isText?: boolean;
}

export const SkeletonBox = (props: IProps) => {
    return <div className={cx(props.isText && textSkeletonHolder, skeletonHolder, props.className)} />;
};

const skeletonHolder = css`
    width: inherit;
    height: 100%;
    position: relative;
    background: linear-gradient(45deg, #ccc, #ddd, #ccc);
    animation: gradientAnimation 2s ease infinite;
    background-size: 400%;
    overflow: hidden;

    @keyframes gradientAnimation {
        0% {
            background-position: -100px;
        }
        50% {
            background-position: 0;
        }
        100% {
            background-position: -100px;
        }
    }

    &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, transparent, ${getThemeVariable("colors-body_bg")}, transparent);
        animation: waveAnimation 3s ease infinite;
        transform: translateX(-100%);
        opacity: 0.25;

        @keyframes waveAnimation {
            0% {
                transform: translateX(-100%);
            }
            50% {
                /* +0.5s of delay between each loop */
                transform: translateX(100%);
            }
            100% {
                transform: translateX(100%);
            }
        }
    }
`;

const textSkeletonHolder = css`
    opacity: 0.4;
    border-radius: 0.4rem;
    display: block;
`;
