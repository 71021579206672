import * as React from "react";
import styled from "@emotion/styled";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    fill?: string;
    orientation?: "up" | "right" | "down" | "left";
    className?: string;
    strokeWidth?: number;
}

export const ArrowWithOrientationIcon = (props: IProps) => {
    return (
        <Arrow
            width={props.width || 30}
            height={props.height || 35}
            viewBox="0 0 12 6.75"
            className={classNames(props.orientation, props.className, "svg-icon")}
        >
            <polyline
                points="1 1 6 5.75 11 1"
                fill="none"
                stroke={props.fill || "#fff"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={props.strokeWidth ? `${props.strokeWidth}` : "2"}
            />
        </Arrow>
    );
};

/*
Styles
 */

const Arrow = styled("svg")`
    &.up {
        transform: rotate(180deg);
    }

    &.right {
        transform: translate(2px, 0) rotate(270deg);
    }

    &.down {
        transform: rotate(360deg);
    }

    &.left {
        transform: translate(-2px, 0) rotate(90deg);
    }
`;
