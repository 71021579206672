import {ReactNode, useCallback} from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {OFFER_BOX_TESTID} from "@web2/gh_page_object_models/offer_list/test_ids";
import {numberWithDelimiter} from "@web2/string_utils";

import {sizeFormat} from "../../../../../app/utils/number";
import {getThemeVariable} from "../../../../../styles/linaria_variable_factory";
import {ellipsis} from "../../../../../styles/mixins_linaria";
import {OfferMarketType, PropertyType} from "../../../../utils/constants_offer";
import {getOfferHeading} from "../../../../utils/get_offer_heading";
import {getShowOfferPrice} from "../../../../utils/get_show_offer_price";
import {getCurrency, isForRent, roomsPluralism} from "../../../../utils/utils";
import {CurrencyWrapper} from "../../top_bar/OfferPrice";
import {IOfferBoxOffer, IOnOfferBoxModalOpen} from "../OfferBox";

interface IProps {
    offer: IOfferBoxOffer;
    isInvestment: boolean;
    isInvestmentWithHref?: boolean;
    noTooltip: boolean;
    openApplicationModal: IOnOfferBoxModalOpen;
    renderCTA?: ReactNode;
}

const regexForStreetPrefix = /ul. |al. |rondo |aleja |os. |pl. |plac /i;

export const OfferBoxDetail = (props: IProps) => {
    const {offer} = props;
    const {property} = props.offer;
    const currency = getCurrency(props.offer.price.currency);
    const propertySize = props.offer.property.size ? props.offer.property.size.toString() : "";

    const firstUpperCase = useCallback((string: string) => {
        // Convert the city name to lowercase and capitalize the first letter
        if (string === string.toUpperCase()) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        // Capitalize the first letter of each word, excluding hyphenated words
        return string
            .split(" ")
            .map((word) => {
                // Check if the word contains a hyphen
                if (word.includes("-")) {
                    // Split the hyphenated word into parts and capitalize each part
                    return word
                        .split("-")
                        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                        .join("-");
                } else {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                }
            })
            .join(" ");
    }, []);

    const getDotColor = () => {
        if (property.type === PropertyType.LOT) {
            return PropertyType.LOT;
        }

        return offer.market_type;
    };

    const showOfferPriceTotal = getShowOfferPrice({offer, priceType: "total"});
    const showOfferPriceM2 = getShowOfferPrice({offer, priceType: "m2"});

    const priceHTML = (
        <>
            {showOfferPriceTotal || showOfferPriceM2 ? (
                <div className={offerBoxPriceHolder}>
                    {offer.price.total && offer.price.total > 0 && showOfferPriceTotal ? (
                        <div>
                            <span className={offerBoxPriceTotal}>{numberWithDelimiter(offer.price.total)}</span>
                            <span className={numbersRoomsAndSpaceText} data-testid={OFFER_BOX_TESTID.MONTHLY_FEE_OR_ONE_TIME_FEE}>
                                {` ${currency}`}
                                {isForRent(offer.deal_type) && "/mies."}
                            </span>
                        </div>
                    ) : null}

                    {offer.price.per_sqm && offer.price.per_sqm > 0 && showOfferPriceM2 ? (
                        <div className={offerBoxPricePerSqmHolder}>
                            <span className={offerBoxPricePerSqm}>
                                {numberWithDelimiter(offer.price.per_sqm)} <CurrencyWrapper currency={currency}>{currency}</CurrencyWrapper>/
                            </span>
                            <span className={offerBoxPricePerSqmSmall}>
                                m<sup>2</sup>
                            </span>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );

    const streetName = offer.property.address_details?.street ? `${offer.property.address_details.street.replace(regexForStreetPrefix, "")}, ` : "";
    const housingEstate = offer.property.address_details?.housing_estate ? `${offer.property.address_details.housing_estate}, ` : "";
    const district = offer.property.address_details?.district ? `${offer.property.address_details.district}, ` : "";
    const city = offer.property.address_details?.city ? offer.property.address_details.city : "";
    const firstUpperCaseDistrict = firstUpperCase(district);
    const firstUpperCaseCity = firstUpperCase(city);
    const detailedAddress = `${streetName}${housingEstate}${firstUpperCaseDistrict}${firstUpperCaseCity}`;
    const modifiedDetailedAddress = detailedAddress.trim().endsWith(",") ? detailedAddress.trim().slice(0, -1) : detailedAddress;

    const mainHeaderHTML = (
        <>
            <TextWithDot bgColor={getDotColor()} data-testid={OFFER_BOX_TESTID.HEADER_OFFERBOX}>
                {offer.market_type !== OfferMarketType.PRIMARY_MARKET && offer.name ? offer.name : getOfferHeading(offer).heading}
            </TextWithDot>
            <address className={offerBoxPropertyLocation}>{modifiedDetailedAddress}</address>
        </>
    );

    const offerBoxDetailsHTML = () => {
        return (
            <article className={offerBoxInfoHolder}>
                <OfferBoxHeading renderCTA={!!props.renderCTA}>{mainHeaderHTML}</OfferBoxHeading>

                <div className={offerBoxFlex}>
                    <div>
                        {property.room_number && (
                            <>
                                <span className={numbersRoomsAndSpaceHighlighted} data-testid={OFFER_BOX_TESTID.NUMBER_OF_ROOMS_OFFERBOX}>
                                    {property.room_number}
                                </span>
                                <span>&nbsp;</span>
                                <span className={numbersRoomsAndSpaceText} data-testid={OFFER_BOX_TESTID.VARIANT_WORD_ROOMS}>
                                    {roomsPluralism(property.room_number)}{" "}
                                </span>
                            </>
                        )}
                        {propertySize && (
                            <span>
                                <span className={numbersRoomsAndSpaceHighlighted}>{property.size && sizeFormat(property.size)}</span>
                                <span className={numbersRoomsAndSpaceText}>
                                    &nbsp;m<sup>2</sup>
                                </span>
                            </span>
                        )}
                    </div>
                    {priceHTML}
                </div>
            </article>
        );
    };

    const offerBoxDetailsLotsHTML = () => {
        return (
            <article className={lotWrapper}>
                <h3 className={offerBoxHeadingLots}>
                    <>{mainHeaderHTML}</>
                    <LotSpace>
                        {property.size && (
                            <>
                                <span className={numbersRoomsAndSpaceHighlighted}>{` ${sizeFormat(property.size)}`}</span>
                                <span className={numbersRoomsAndSpaceText}>
                                    &nbsp;m<sup>2</sup>
                                </span>
                            </>
                        )}
                    </LotSpace>
                </h3>
                <OfferBoxFlexLot>{priceHTML}</OfferBoxFlexLot>
            </article>
        );
    };

    return (
        <>
            {property.type !== PropertyType.LOT && offerBoxDetailsHTML()}
            {property.type === PropertyType.LOT && offerBoxDetailsLotsHTML()}
            {props.renderCTA && props.renderCTA}
        </>
    );
};

interface ITextWithDotProps {
    bgColor: OfferMarketType.PRIMARY_MARKET | OfferMarketType.AFTERMARKET | "gray_light" | PropertyType.LOT;
}

const offerBoxInfoHolder = css`
    padding: 0.6rem 1rem 1rem;
    font-family: ${getThemeVariable("fonts-font_family")}; // for google map info window
    display: flex;
    flex-direction: column;
    flex: 1 0;
    gap: 0.4rem;
    justify-content: space-between;
    background: #fff;
    position: relative;
`;

const TextWithDot = styled.div<ITextWithDotProps>`
    ${ellipsis};
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0.8rem;
    padding-left: 1.2rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6;

    &:last-child {
        margin-right: 0;
    }

    &:before {
        position: absolute;
        top: 0.7rem;
        content: "";
        left: 0;
        width: 0.8rem;
        height: 0.8rem;
        background-color: ${(props) =>
            props.bgColor === OfferMarketType.PRIMARY_MARKET
                ? getThemeVariable("colors-primary_market")
                : props.bgColor === OfferMarketType.AFTERMARKET
                  ? getThemeVariable("colors-aftermarket")
                  : props.bgColor === PropertyType.LOT
                    ? getThemeVariable("colors-lot")
                    : getThemeVariable("colors-gray_light")};
        border-radius: 50%;
        vertical-align: middle;
    }
`;

const offerBoxPriceHolder = css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    line-height: 1.1rem;
`;

const numbersRoomsAndSpaceHighlighted = css`
    font-weight: 700;
    font-size: 1.9rem;
    line-height: 3rem;
`;

const offerBoxPriceTotal = css`
    font-weight: 700;
    font-size: 1.9rem;
    line-height: 3rem;
    margin: 0;
    color: ${getThemeVariable("colors-brand_primary")};
`;

const numbersRoomsAndSpaceText = css`
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: ${getThemeVariable("colors-gray_dark")};
`;

const offerBoxPricePerSqm = css`
    color: ${getThemeVariable("colors-gray_lighter")};
    margin: 0 0 0 0.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    white-space: nowrap;
`;

const offerBoxPricePerSqmHolder = css`
    margin-top: -1px;
`;

const offerBoxPricePerSqmSmall = css`
    color: ${getThemeVariable("colors-gray_lighter")};
    font-size: 0.8rem;
    font-weight: 600;
    white-space: nowrap;
`;

const offerBoxPropertyLocation = css`
    ${ellipsis};
    color: ${getThemeVariable("colors-gray_lighter")};
    margin-left: 1.2rem;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
`;

const offerBoxFlex = css`
    margin-left: 1.7rem;
    margin-right: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 4rem;
`;

const OfferBoxFlexLot = styled.div`
    position: absolute;
    margin-left: 1.2rem;
    bottom: -0.2rem;
    right: 2rem;
`;

const OfferBoxHeading = styled.h3<{renderCTA: boolean}>`
    margin: 0 0.5rem;
    height: ${(props) => (props.renderCTA ? "6.9rem" : "auto")};
`;

const offerBoxHeadingLots = css`
    margin: 0.5rem 0.8rem 0.5rem 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const LotSpace = styled.div`
    margin-left: 1.2rem;
    margin-top: 0.6rem;
    margin-bottom: 1rem;
`;

const lotWrapper = css`
    position: relative;
`;
