import * as React from "react";
import styled from "@emotion/styled";
import {numberWithDelimiter} from "@web2/string_utils";

import {Flex} from "../../../../app/atoms/Flex";
import {OfferDealType} from "../../../utils/constants_offer";
import {getCurrency, isForRent} from "../../../utils/utils";
import {Price as PriceStandard, PricePerSqm} from "../../atoms/Price";

interface Props {
    offer: {
        deal_type: OfferDealType;
        price: {
            per_sqm: number | null;
            total: number | null;
            currency?: string;
        };
    };
}

export const OfferPrice = (props: Props) => {
    const {offer} = props;
    const currency = getCurrency(offer.price.currency);

    return (
        <OfferPriceWrapper alignItems="center">
            {offer.price.total && (
                <Price>
                    {numberWithDelimiter(offer.price.total)} {currency}
                    {isForRent(offer.deal_type) && "/miesiąc"}
                </Price>
            )}

            {offer.price.per_sqm && (
                <PricePer>
                    {numberWithDelimiter(offer.price.per_sqm)} <CurrencyWrapper currency={currency}>{currency}</CurrencyWrapper>/m<sup>2</sup>
                </PricePer>
            )}
        </OfferPriceWrapper>
    );
};

/**
 * Styles
 */

const Price = styled(PriceStandard)`
    margin: 0;
    flex-shrink: 0;
`;

const PricePer = styled(PricePerSqm)`
    flex-shrink: 0;
`;

const OfferPriceWrapper = styled(Flex)`
    .ask-for-offer-btn {
        display: none;

        @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
            display: block;
            margin-left: 1.5rem;
        }
    }
`;
interface CurrencyProps {
    currency: string;
}
export const CurrencyWrapper = styled.span<CurrencyProps>`
    text-transform: ${({currency}) => (currency && currency !== "zł" ? "uppercase" : "lowercase")};
`;
